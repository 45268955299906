import React from 'react';
import moment from 'moment';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading, H2 } from '../../components';

import css from './ListingPage.module.css';
import { isArray, isEmpty } from 'lodash';

const longDaysLookUp = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};

const SectionWeatherForecast = props => {
  const { id, forecast, fetchWeatherForecastError } = props;

  return isArray(forecast) && !isEmpty(forecast) ? (
    <div id={id} className={css.sectionWeatherForecast}>
      <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
        <FormattedMessage id="ListingPage.weatherForecastTitle" />
      </Heading>
      {fetchWeatherForecastError ? (
        <H2 className={css.errorText}>
          <FormattedMessage id="ListingPage.weatherForecastError" />
        </H2>
      ) : null}
      {/* TODO: After river data plugin imported update here */}
      {/* <Reviews reviews={reviews} /> */}
      <div className={css.listingWeatherDataBox}>
        {forecast?.slice(0, forecast.length - 1).map(data => {
          const icon = data.weather[0].icon.replace('n', 'd');
          const day = longDaysLookUp[moment(data.date).day()];
          return (
            <div key={data.date} className={css.weatherDay}>
              <p>
                <img
                  className={css.weatherIcon}
                  src={`https://cdn.jsdelivr.net/npm/open-weather-icons@0.0.7/src/svg/${icon}.svg`}
                />
              </p>
              <p className={css.weatherParagraph}>
                <strong>{day}</strong>
                <br />
                <em className={css.weatherParagraphItalic}>
                  {moment(data.date).format('M/D/YYYY')}
                </em>
                <br />
                <strong>{data.maxTemp}℉</strong> / {data.minTemp}℉
              </p>
            </div>
          );
        })}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default SectionWeatherForecast;
