import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading, H2, RiverDataChart } from '../../components';

import css from './ListingPage.module.css';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { getHour } from '../../util/dates';
import { useMemo } from 'react';
import { useCallback } from 'react';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const variableNames = {
  streamFlow: 'Streamflow',
  gageHeight: 'Gage',
};

function returnSelectedPortion(riverDataObject, variableName) {
  return (
    riverDataObject?.value?.timeSeries.find(riverData =>
      riverData.variable.variableName.includes(variableName)
    )?.values[0]?.value ?? []
  );
}

const SectionRiverData = props => {
  const { id, riverDataObject, fetchRiverDataError } = props;

  if (fetchRiverDataError) {
    return (
      <div id={id} className={css.sectionRiverData}>
        <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
          <FormattedMessage id="ListingPage.riverDataTitle" />
        </Heading>
        <H2 className={css.errorText}>
          <FormattedMessage id="ListingPage.riverDataError" />
        </H2>
      </div>
    );
  }

  let streamFlow = [];
  let gageHeight = [];
  if (riverDataObject?.ResultList) {
    streamFlow = riverDataObject.ResultList.filter(
      resultList => resultList.parameter === 'DISCHRG'
    ).map(resultList => {
      return {
        dateTime: resultList.measDate,
        value: resultList.measValue,
      };
    });
    gageHeight = riverDataObject.ResultList.filter(
      resultList => resultList.parameter !== 'DISCHRG'
    ).map(resultList => {
      return {
        dateTime: resultList.measDate,
        value: resultList.measValue,
      };
    });
  }

  const streamFlowData =
    streamFlow.length > 0
      ? streamFlow
      : useMemo(() => returnSelectedPortion(riverDataObject, variableNames.streamFlow), [
          riverDataObject,
        ]);
  const gageHeightData =
    gageHeight.length > 0
      ? gageHeight
      : useMemo(() => returnSelectedPortion(riverDataObject, variableNames.gageHeight), [
          riverDataObject,
        ]);

  /** This section will require some refactoring */

  const streamFlowValues = [];
  const streamFlowLabels = [];
  const streamFlowLabelsWithTime = [];

  const gageHeightValues = [];
  const gageHeightLabels = [];
  const gageHeightLabelsWithTime = [];

  if (gageHeightData && gageHeightData.length > 0) {
    gageHeightData.forEach(val => {
      gageHeightLabels.push(val.dateTime.split('T')[0]);
      gageHeightLabelsWithTime.push(val.dateTime.split('T'));
      gageHeightValues.push(val.value);
    });
  }

  if (streamFlowData && streamFlowData.length > 0) {
    streamFlowData.forEach(val => {
      streamFlowValues.push(val.value);
      const dateComponents = val.dateTime.split('T')[0].split('-');
      let hour = getHour(val.dateTime);

      let meridian = '';
      if (hour >= 0 && hour <= 11) {
        meridian = 'AM';
        if (hour == 0) {
          hour = 12;
        }
      } else {
        if (hour != 12) {
          hour = hour - 12;
        }
        meridian = 'PM';
      }
      const timeComponents =
        hour +
        ':' +
        val.dateTime
          .split('T')[1]
          .split('.')[0]
          .split(':')[1] +
        ' ' +
        meridian;

      const formattedDate = parseInt(dateComponents[1]) + '/' + parseInt(dateComponents[2]);
      const formattedDateWithTime =
        dateComponents[1] +
        '/' +
        dateComponents[2] +
        '/' +
        dateComponents[0] +
        ' ' +
        timeComponents;
      streamFlowLabels.push(formattedDate);
      streamFlowLabelsWithTime.push(formattedDateWithTime);
    });
  }

  const gageHeightMin = Math.min(...gageHeightValues);
  const gageHeightMax = Math.max(...gageHeightValues);
  const gageHeightDiff = gageHeightMax - gageHeightMin;
  const gageHeightScaleMin = parseFloat((gageHeightMin - gageHeightDiff / 2).toFixed(2));
  const gageHeightScaleMax = parseFloat((gageHeightMax + gageHeightDiff / 20).toFixed(2));

  const streamFlowMin = Math.min(...streamFlowValues);
  const streamFlowMax = Math.max(...streamFlowValues);
  const streamFlowDiff = streamFlowMax - streamFlowMin;

  const datasets = [];
  const scales = {
    x: {
      title: {
        text: 'Date',
        display: true,
      },
      ticks: {
        maxTicksLimit: 5,
        maxRotation: 0,
      },
    },
  };

  if (gageHeightValues.length > 0) {
    datasets.push({
      data: gageHeightValues,
      label: 'Gauge Height (Feet)',
      borderColor: '#002C4C',
      backgroundColor: '#002C4C',
      fill: false,
      yAxisID: 'y1',
    });
    scales['y1'] = {
      title: {
        text: 'Gauge Height (Feet)',
        display: true,
      },
      type: 'linear',
      display: true,
      position: 'right',
      min: gageHeightScaleMin,
      max: gageHeightScaleMax,
      grid: {
        drawOnChartArea: false,
      },
      ticks: {
        maxTicksLimit: 6,
        maxRotation: 0,
        stepSize: 0.01,
      },
    };
  }
  if (streamFlowValues.length > 0) {
    datasets.push({
      data: streamFlowValues,
      label: 'Flows (CFS)',
      borderColor: '#0094FF',
      backgroundColor: '#0094FF',
      fill: false,
      yAxisID: 'y',
    });
    scales['y'] = {
      title: {
        text: 'Flows (CFS)',
        display: true,
      },
      type: 'linear',
      display: true,
      position: 'left',
      min: Math.floor((streamFlowMin - streamFlowDiff / 20) / 10) * 10,
      max: Math.ceil((streamFlowMax + streamFlowDiff / 2) / 10) * 10,
      ticks: {
        maxTicksLimit: 6,
        maxRotation: 0,
      },
    };
  }

  const options = {
    data: {
      datasets: datasets,
      labels: streamFlowLabels,
    },
    options: {},
  };

  return (
    <div id={id} className={css.sectionRiverData}>
      <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
        <FormattedMessage id="ListingPage.riverDataTitle" />
      </Heading>
      {/* TODO: After river data plugin imported update here */}
      {/* <RiverDataChart riverDataObject={riverDataObject} /> */}
      {datasets?.length ? (
        <Line
          options={{
            spanGaps: false,
            elements: {
              point: {
                radius: 0,
              },
            },
            responsive: true,
            interaction: {
              mode: 'index',
              intersect: false,
            },
            stacked: false,
            scales: scales,
            plugins: {
              tooltip: {
                callbacks: {
                  title: function(context) {
                    return streamFlowLabelsWithTime[context[0].dataIndex];
                  },
                },
                intersect: false,
              },
            },
          }}
          data={options.data}
        />
      ) : (
        <FormattedMessage id="ListingPage.noRiverData" />
      )}
    </div>
  );
};

export default SectionRiverData;
